import React from "react";
import clsx from "clsx";
import { Container, Text } from "@atoms";
import PeopleTopPattern from "@svg/PeopleTopPattern";

const bgColor = {
  teal: "bg-teal",
  yellow: "bg-yellow",
  purple: "bg-purple",
  pink: "bg-pink",
  slate: "bg-slate",
};

const OverviewGrid = ({ items, color = "teal", descriptor, heading }) => {
  return (
    <div className="my-10 sm:my-20">
      <Container>
        <div
          className={clsx(
            "relative z-10 overflow-hidden rounded-4xl p-10 md:p-20",
            bgColor[color]
          )}
        >
          <div className="relative z-10 flex flex-col gap-8">
            <div
              className={clsx("flex flex-col items-center gap-4", {
                "text-slate": color === "teal" || color === "yellow",
                "text-white": color !== "teal" && color !== "yellow",
              })}
            >
              {heading && <Text variant="h3">{heading}</Text>}
              {descriptor && <Text variant="body">{descriptor}</Text>}
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              {items?.map(item => {
                return (
                  <div className="rounded-t-2xl rounded-br-2xl bg-white p-4 sm:min-h-[5.25em]">
                    <Text
                      className={clsx(
                        "text-pretty text-lg font-bold leading-tight sm:text-xl",
                        {
                          "text-purple": color !== "slate",
                          "text-slate": color === "slate",
                        }
                      )}
                    >
                      {item.title}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
          <PeopleTopPattern
            className={clsx("absolute -left-4 -top-28 w-[27.5rem]", {
              "text-purple": color === "teal",
              "text-white": color === "yellow",
              "text-teal": color !== "teal" && color !== "yellow",
            })}
          />
        </div>
      </Container>
    </div>
  );
};

export default OverviewGrid;
